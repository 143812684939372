var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.outOfStock
      ? _c(
          "form",
          {
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.add.apply(null, arguments)
              },
            },
          },
          [
            _c("label", { attrs: { for: "quantity" } }, [_vm._v("Quantity")]),
            _vm._v(" "),
            _vm.showEstimate
              ? _c("div", { staticClass: "estimate text-primary mt-1" }, [
                  _vm._v(_vm._s(_vm.estimate)),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.quantity,
                    expression: "quantity",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "number",
                  name: "quantity",
                  min: 0,
                  max: _vm.limit,
                  step: 1,
                },
                domProps: { value: _vm.quantity },
                on: {
                  change: _vm.getEstimate,
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.quantity = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                [_vm._v(_vm._s(_vm.displayText))]
              ),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }