<template>
  <div class="my-3">
    <h4>Delivery Details</h4>
    <div class="mb-3 row">
      <div class="col-4">
        <label for="deliveryCityID" class="form-label">Delivery City:</label>
        <select name="deliveryCityID" v-model="cart.data.order.data.deliveryCityID" class="form-control"
                @change="updated">
          <option value=""></option>
          <option v-for="city in deliveryCities" :value="city.id">{{ city.name }}</option>
        </select>
      </div>
      <div class="col-4 mb-3">
        <label for="deliveryDatePicker" class="form-label">Delivery Date</label>
        <input type="text" name="deliveryDatePicker" id="deliveryDatePicker" class="form-control" readonly/>
      </div>
      <div class="col-4 mb-3">
        <label for="deliveryTime" class="form-label">Delivery Time:</label>
        <select name="deliveryTime" class="form-control" v-model="cart.data.order.data.deliveryTimeOption"
                @change="timeUpdated">
          <option value=""></option>
          <option v-for="(value, key) in deliveryTimeOptions" :value="key">{{ value }}</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import Cart from "../../../../../../../packages/sales/src/Sales/Javascript/models/cart";
import AirDatepicker from "air-datepicker";
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en';
import {moment} from "../../../../../../../concrete/bedrock/assets/fullcalendar/dist/fullcalendar";

export default {
  name: 'DeliveryFields',
  mounted() {
    const self = this;
    this.datePicker = new AirDatepicker('#deliveryDatePicker', {
      autoclose: true,
      dateFormat: 'yyyy-MM-dd',
      locale: localeEn,
      minDate: this.firstAvailableDeliveryDate,
      onRenderCell({date, cellType}) {
        if (cellType === 'day') {
          if (self.excludeDate(date)) {
            return {
              disabled: true,
              classes: 'disabled-class'
            }
          }
        }
      },
      onSelect({date, formattedDate, datepicker}) {
        self.$emit('update:delivery-date', formattedDate);
      }
    });
    let deliveryDate = null;
    if (this.cart.data.order.data.deliveryDateTime !== null) {
      deliveryDate = moment(this.cart.data.order.data.deliveryDateTime +  " 12:00:00");
      if (deliveryDate.isValid()) {
        this.datePicker.update({
          selectedDates: [deliveryDate]
        })
      }
    }
  },
  computed: {
    deliveryDateTime: function () {
      return this.cart.data.order.data.deliveryDateTime;
    },
    firstAvailableDeliveryDate: function () {
      return this.deliveryDateData.meta.firstAvailableDeliveryDate ? this.deliveryDateData.meta.firstAvailableDeliveryDate : "";
    },
    disabledDates: function () {
      const deliveryDates = this.deliveryDateData.data.filter(item => item.type === 'both' || item.type === 'delivery');
      return deliveryDates.map(item => item.dateYMD);
    }
  },
  data() {
    return {
      datePicker: null
    }
  },
  props: {
    cart: {
      type: Object,
      required: true,
      default: new Cart()
    },
    deliveryCities: {
      type: Array,
      required: true,
    },
    deliveryDateData: {
      type: Object,
      required: true
    },
    deliveryTimeOptions: {
      type: Object,
      required: false,
      default: () => {
      }
    }

  },
  methods: {
    excludeDate: function (date) {
      this.deliveryDateData.data.forEach(function (closedDate) {
        if (moment(date).isSame(closedDate.dateYMD, 'day')) {
          return true;
        }
      });

      return false;
    },
    updated() {
      this.$emit('update:delivery-city-id', this.cart.data.order.data.deliveryCityID);
    },
    timeUpdated() {
      this.$emit('update:delivery-time-option', this.cart.data.order.data.deliveryTimeOption);
    }
  },
  watch: {
    firstAvailableDeliveryDate: function () {
      this.datePicker.update({
        minDate: this.firstAvailableDeliveryDate,
      });
      this.datePicker.disableDate(this.disabledDates);
    }
  }
}
</script>