<template>
    <div class="my-3">
        <button
            @click="updateOrder"
            class="btn btn-primary"
            v-if="showButton"
        >Continue
        </button>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";
import Cart from "../../../../../../../packages/sales/src/Sales/Javascript/models/cart";
import Order from "../../../../../../../packages/sales/src/Sales/Javascript/models/order";

export default {
    name: 'ContinueButton',
    computed: {
        showButton() {
            return this.cart.data.order.data.lineItems.data.length > 0;
        }
    },
    mounted() {
    },
    props: {
        cart: {
            type: Object,
            required: true,
            default: new Cart()
        }
    },
    methods: {
        updateOrder() {
            const self = this;
            const order = new Order();
            order.setData(this.cart.data.order.data);
            const formData = order.getFormData();
            axios
                .post('api/v1/sales/cart', formData)
                .then(function (response) {
                    window.location.href = response.data.data.order.data.confirmURL;
                })
                .catch(function (error) {
                    Swal.fire({
                        animation: false,
                        icon: "error",
                        title: "Error",
                        html: error.response.data.meta.summary,
                    })
                });
        }
    }
}
</script>