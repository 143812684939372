var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "cart-details" }, [
    _vm.cart.loaded && _vm.cartItems.length === 0
      ? _c("div", { staticClass: "cart-empty" }, [
          _vm._v("\n        Your cart is currently empty\n    "),
        ])
      : _c(
          "div",
          { staticClass: "bg-light-grey rounded p-3 container-fluid mb-3" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.cartItems, function (item) {
              return _c("cart-line-item", {
                key: item.id,
                attrs: { item: item },
              })
            }),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-8" }, [_vm._v("Sub Total")]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4 text-end" }, [
                _vm._v(_vm._s(_vm.cart.data.order.data.subTotal)),
              ]),
            ]),
            _vm._v(" "),
            _vm.cart.data.order.data.shippingTotalCents > 0
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-8" }, [_vm._v("Shipping")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4 text-end" }, [
                    _vm._v(_vm._s(_vm.cart.data.order.data.shippingTotal)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.cart.data.order.data.taxCents > 0
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-8" }, [_vm._v("GST")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4 text-end" }, [
                    _vm._v(_vm._s(_vm.cart.data.order.data.totalGST)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.cart.data.order.data.pstCents > 0
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-8" }, [_vm._v("PST")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-4 text-end" }, [
                    _vm._v(_vm._s(_vm.cart.data.order.data.totalPST)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row fw-bold" }, [
              _c("div", { staticClass: "col-8" }, [_vm._v("Total")]),
              _vm._v(" "),
              _c("div", { staticClass: "col-4 text-end" }, [
                _vm._v(_vm._s(_vm.cart.data.order.data.total)),
              ]),
            ]),
          ],
          2
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "row fw-bold gx-1 py-2 mb-2 border-bottom" },
      [
        _c("div", { staticClass: "col-4" }, [_vm._v("Item")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2 text-end" }, [_vm._v("Quantity")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-2 text-end" }, [_vm._v("Price")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-3 text-end" }, [_vm._v("Total")]),
        _vm._v(" "),
        _c("div", { staticClass: "col-1 text-end" }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }