<template>
    <div class="mb-3" v-if="hasItems">
        <h2 class="mb-3 mt-0">Shipping / Delivery Details</h2>
        <div class="row">
            <div class="col-6">
                <label class="form-label">Delivery or Pickup</label>
                <select class="form-select" v-model="cart.data.order.data.deliveryPickup" @change="deliveryPickupOptionChanged">
                    <option value="delivery">Delivery</option>
                    <option value="pickup">Pickup</option>
                </select>
            </div>
        </div>
        <div v-if="cart.data.order.data.deliveryPickup === 'delivery'">
            <delivery-fields
                :cart="cart"
                :delivery-cities="deliveryCities"
                :delivery-date-data="deliveryDateData"
                :delivery-time-options="deliveryTimeOptions"
                @update:delivery-city-id="deliveryCityIDUpdated"
                @update:delivery-date="deliveryDateUpdated"
            ></delivery-fields>
        </div>
        <div v-if="cart.data.order.data.deliveryPickup === 'pickup'">
            <pickup-fields
                :cart="cart"
                :delivery-date-data="deliveryDateData"
                :delivery-time-options="deliveryTimeOptions"
                @update:pickup-date="pickupDateUpdated"
            ></pickup-fields>
        </div>
    </div>
</template>

<script>
import Cart from "../../../../../../../packages/sales/src/Sales/Javascript/models/cart";
import DeliveryFields from "./DeliveryFields.vue";
import PickupFields from "./PickupFields.vue";
import axios from "axios";
import {moment} from "../../../../../../../concrete/bedrock/assets/fullcalendar/dist/fullcalendar";

export default {
    name: 'ShippingEntry',
    components: {DeliveryFields, PickupFields},
    computed: {
        hasItems() {
            return this.cart.data.order.data.lineItems.data.length > 0
        }
    },
    props: {
        cart: {
            type: Object,
            required: true,
            default: new Cart()
        },
        deliveryCities: {
            type: Array,
            required: true,
        },
        deliveryDateData: {
            type: Object,
            required: true
        },
        deliveryTimeOptions: {
            type: Object,
            required: false,
            default: () => {
                data: []
            }
        }
    },
    methods: {
        deliveryCityIDUpdated(id) {
            this.$emit('update:delivery-city-id', id)
        },
        deliveryDateUpdated(date) {
            this.$emit('update:delivery-date', date)
            this.updateTimeOptions()
        },
        deliveryPickupOptionChanged() {
            this.updateTimeOptions()
            this.updateDateOptions()
        },
        pickupDateUpdated(date) {
            this.$emit('update:pickup-date', date)
            this.updateTimeOptions()
        },
        updateDateOptions() {
            this.$emit('update:date-options')
        },
        updateTimeOptions() {
            this.$emit('update:time-options')
        }
    }
}
</script>