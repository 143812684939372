<template>
    <div class="mb-3" v-if="hasItems">
        <h2 class="mb-3 mt-0">Shipping / Delivery Details</h2>
        <div class="row">
            <div class="col-6">
                <label class="form-label">Delivery or Pickup</label>
                <select class="form-select" v-model="cart.data.order.data.deliveryPickup" @change="updateTimeOptions">
                    <option value="delivery">Delivery</option>
                    <option value="pickup">Pickup</option>
                </select>
            </div>
        </div>
        <div v-if="cart.data.order.data.deliveryPickup === 'delivery'">
            <delivery-fields
                :cart="cart"
                :delivery-cities="deliveryCities"
                :delivery-date-data="deliveryDateData"
                :delivery-time-options="deliveryTimeOptions"
                @update:delivery-city-id="deliveryCityIDUpdated"
                @update:delivery-date="deliveryDateUpdated"
            ></delivery-fields>
        </div>
        <div v-if="cart.data.order.data.deliveryPickup === 'pickup'">
            <pickup-fields
                :cart="cart"
                :delivery-date-data="deliveryDateData"
                :delivery-time-options="deliveryTimeOptions"
                @update:pickup-date="pickupDateUpdated"
            ></pickup-fields>
        </div>
    </div>
</template>

<script>
import Cart from "../../../../../../../packages/sales/src/Sales/Javascript/models/cart";
import DeliveryFields from "./DeliveryFields.vue";
import PickupFields from "./PickupFields.vue";
import axios from "axios";
import {moment} from "../../../../../../../concrete/bedrock/assets/fullcalendar/dist/fullcalendar";

export default {
    name: 'ShippingEntry',
    components: {DeliveryFields, PickupFields},
    computed: {
        hasItems() {
            return this.cart.data.order.data.lineItems.data.length > 0;
        }
    },
    mounted() {
        this.updateTimeOptions()
    },
    data() {
        return {
            deliveryTimeOptions: {}
        }
    },
    props: {
        cart: {
            type: Object,
            required: true,
            default: new Cart()
        },
        deliveryCities: {
            type: Array,
            required: true,
        },
        deliveryDateData: {
            type: Object,
            required: true
        }
    },
    methods: {
        deliveryCityIDUpdated(id) {
            this.$emit('update:delivery-city-id', id);
        },
        deliveryDateUpdated(date) {
            this.$emit('update:delivery-date', date);
            this.updateTimeOptions(date);
        },
        pickupDateUpdated(date) {
            this.$emit('update:pickup-date', date);
            this.updateTimeOptions(date);
        },
        updateTimeOptions() {
            const self = this;
            const formData = new FormData();
            let deliveryDate = null;
            if (this.cart.data.order.data.deliveryDateTime !== null) {
                deliveryDate = moment(this.cart.data.order.data.deliveryDateTime);
                if (deliveryDate.isValid()) {
                    formData.append('date', deliveryDate);
                }
            }
            formData.append('type', this.cart.data.order.data.deliveryPickup);
            axios.post('/api/v1/sales/time-options', formData)
                .then(function (response) {
                    self.deliveryTimeOptions = response.data;
                });
            self.$emit('update:refresh-shipping');
        }
    }
}
</script>