var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("a", { staticClass: "position-relative", attrs: { href: "/cart" } }, [
      _vm.currentNumItems > 0
        ? _c(
            "span",
            {
              staticClass:
                "position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success",
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.currentNumItems) + "\n        "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("i", { staticClass: "bi bi-cart4" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }