import AddToCart from "./components/AddToCart.vue";
import CartDetails from "./components/CartDetails.vue";
import ContactDetails from "./components/ContactDetails.vue";
import ContinueButton from "./components/ContinueButton.vue";
import ShippingEntry from "./components/ShippingEntry.vue";
import CartIcon from "./components/CartIcon.vue";
import Cart from "../../../../../../packages/sales/src/Sales/Javascript/models/cart";
import axios from "axios";
import Order from "../../../../../../packages/sales/src/Sales/Javascript/models/order";
import {moment} from "../../../../../../concrete/bedrock/assets/fullcalendar/dist/fullcalendar";

window.addEventListener("load", function () {

    // first check if we are in edit mode for the page - weird things happen if we try
    // to initialize a vue app as it prevents editable areas from interacting correctly.
    const html = document.getElementsByTagName('html')[0]
    if (html.classList.contains("ccm-edit-mode")) {
        return
    }
    Concrete.Vue.activateContext('cms', function(Vue, config) {
        new Vue({
            el: '#page-header',
            components: {
                CartIcon
            }
        })
    })

    const productPageElement = document.querySelectorAll('.product-list-wrapper')
    const cartPageElement = document.getElementById('cart-page-main')

    if (productPageElement.length > 0) {
        productPageElement.forEach(el => {
            Concrete.Vue.activateContext('cms', function (Vue, config) {
                new Vue({
                    el: el,
                    components: {
                        AddToCart
                    }
                })
            });
        })
    }

    if (cartPageElement) {
        Concrete.Vue.activateContext('cms', function (Vue, config) {
            new Vue({
                el: '#cart-page-main',
                data() {
                    return {
                        cart: new Cart(),
                        deliveryCities: {
                            data: []
                        },
                        deliveryDateData: {
                            data: {
                                closedDates: [],
                            },
                            meta: {
                                firstAvailableDeliveryDate: null,
                                firstAvailablePickupDate: null
                            }
                        },
                        deliveryTimeOptions: {
                            data: [],
                        }
                    }
                },
                components: {
                    CartDetails,
                    ContactDetails,
                    ContinueButton,
                    ShippingEntry
                },
                mounted() {
                    this.loadCart();
                    this.loadStoreOptions();
                    window.addEventListener('store.cart.refresh', this.loadCart)
                },
                methods: {
                    loadCart: function () {
                        const self = this;
                        self.cart.loaded = false;
                        axios
                            .get('/api/v1/sales/cart')
                            .then(function (response) {
                                self.cart = response.data
                                self.cart.loaded = true
                                self.updateDateOptions()
                                self.updateTimeOptions()
                            })
                    },
                    loadStoreOptions: function () {
                        const self = this;
                        axios.get('/api/v1/sales/options')
                            .then(function (response) {
                                self.deliveryCities = response.data.deliveryCities
                                self.deliveryDateData.meta = response.data.deliveryDateData.meta

                            });
                    },
                    billingCityIDUpdated(cityID) {
                        this.cart.data.order.data.billingCityID = cityID
                    },
                    deliveryCityIDUpdated(cityID) {
                        this.cart.data.order.data.deliveryCityID = cityID
                        this.refreshShipping()
                    },
                    deliveryDateUpdated(date) {
                        // NOTE: this is used for both delivery and pickup date selections
                        this.cart.data.order.data.deliveryDateTime = date
                        this.updateTimeOptions()
                        // updating the time options will update the shipping as well so we do not need to do it here
                        // this.refreshShipping()
                    },
                    refreshShipping: function () {
                        const self = this
                        const order = new Order()
                        order.setData(this.cart.data.order.data)
                        const formData = order.getFormData()

                        axios.post('/api/v1/sales/cart/shipping', formData)
                            .then(function (response) {
                                self.cart = response.data
                                self.cart.loaded = true
                            }).catch(function (error) {
                            console.error(error)
                        });
                    },
                    updateDateOptions() {
                        if (!this.cart.data.order.data.deliveryPickup) {
                            return
                        }
                        const self = this
                        const formData = new FormData()
                        formData.append('type', this.cart.data.order.data.deliveryPickup)
                        axios.post('/api/v1/sales/date-options', formData)
                            .then(function (response) {
                                self.deliveryDateData.data = response.data.deliveryDateData.data
                            })
                    },
                    updateTimeOptions() {
                        if (!this.cart.data.order.data.deliveryPickup) {
                            return
                        }
                        const self = this
                        const formData = new FormData()
                        let deliveryDate = null
                        if (this.cart.data.order.data.deliveryDateTime !== null) {
                            deliveryDate = moment(this.cart.data.order.data.deliveryDateTime)
                            if (deliveryDate.isValid()) {
                                formData.append('date', deliveryDate)
                            }
                        }
                        formData.append('type', this.cart.data.order.data.deliveryPickup);
                        axios.post('/api/v1/sales/time-options', formData)
                            .then(function (response) {
                                self.deliveryTimeOptions = response.data
                            })
                        this.refreshShipping()
                    }
                },
                watch: {}
            })
        })
    }
});