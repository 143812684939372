<template>
    <div class="cart-details">
        <div class="cart-empty" v-if="cart.loaded && cartItems.length === 0">
            Your cart is currently empty
        </div>
        <div v-else class="bg-light-grey rounded p-3 container-fluid mb-3">
            <div class="row fw-bold gx-1 py-2 mb-2 border-bottom">
                <div class="col-4">Item</div>
                <div class="col-2 text-end">Quantity</div>
                <div class="col-2 text-end">Price</div>
                <div class="col-3 text-end">Total</div>
                <div class="col-1 text-end"></div>
            </div>
            <cart-line-item
                v-for="item in cartItems"
                :key="item.id"
                :item="item"
            ></cart-line-item>
            <hr />
            <div class="row">
                <div class="col-8">Sub Total</div>
                <div class="col-4 text-end">{{ cart.data.order.data.subTotal }}</div>
            </div>
            <div class="row" v-if="cart.data.order.data.shippingTotalCents > 0">
                <div class="col-8">Shipping</div>
                <div class="col-4 text-end">{{ cart.data.order.data.shippingTotal }}</div>
            </div>
            <div class="row" v-if="cart.data.order.data.taxCents > 0">
                <div class="col-8">GST</div>
                <div class="col-4 text-end">{{ cart.data.order.data.totalGST }}</div>
            </div>
            <div class="row" v-if="cart.data.order.data.pstCents > 0">
                <div class="col-8">PST</div>
                <div class="col-4 text-end">{{ cart.data.order.data.totalPST }}</div>
            </div>
            <hr />
            <div class="row fw-bold">
                <div class="col-8">Total</div>
                <div class="col-4 text-end">{{ cart.data.order.data.total }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import CartLineItem from "./CartLineItem.vue";
import Cart from "../../../../../../../packages/sales/src/Sales/Javascript/models/cart";

export default {
    name: 'CartDetails',
    components: {CartLineItem},
    computed: {
        cartItems() {
            return this.cart.data.order.data.lineItems.data;
        }
    },
    props: {
        cart: {
            type: Object,
            required: true,
            default: new Cart()
        }
    }
}
</script>