var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row gx-1 mb-1" }, [
    _c("div", { staticClass: "col-4" }, [
      _vm._v(_vm._s(_vm.lineItem.productName)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-2 text-end" }, [
      _vm._v(_vm._s(_vm.lineItem.quantity) + " " + _vm._s(_vm.lineItem.units)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-2 text-end" }, [
      _vm._v(_vm._s(_vm.lineItem.productPrice)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-3 text-end" }, [
      _vm._v(_vm._s(_vm.lineItem.subTotal)),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-1 text-end" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-sm btn-outline-danger",
          on: { click: _vm.confirmRemoveItem },
        },
        [_c("i", { staticClass: "bi bi-x" })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }