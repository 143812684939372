var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasItems
    ? _c("div", { staticClass: "mb-3" }, [
        _c("h2", { staticClass: "mb-3 mt-0" }, [
          _vm._v("Shipping / Delivery Details"),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _c("label", { staticClass: "form-label" }, [
              _vm._v("Delivery or Pickup"),
            ]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.cart.data.order.data.deliveryPickup,
                    expression: "cart.data.order.data.deliveryPickup",
                  },
                ],
                staticClass: "form-select",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.cart.data.order.data,
                        "deliveryPickup",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    _vm.updateTimeOptions,
                  ],
                },
              },
              [
                _c("option", { attrs: { value: "delivery" } }, [
                  _vm._v("Delivery"),
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "pickup" } }, [
                  _vm._v("Pickup"),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.cart.data.order.data.deliveryPickup === "delivery"
          ? _c(
              "div",
              [
                _c("delivery-fields", {
                  attrs: {
                    cart: _vm.cart,
                    "delivery-cities": _vm.deliveryCities,
                    "delivery-date-data": _vm.deliveryDateData,
                    "delivery-time-options": _vm.deliveryTimeOptions,
                  },
                  on: {
                    "update:delivery-city-id": _vm.deliveryCityIDUpdated,
                    "update:delivery-date": _vm.deliveryDateUpdated,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.cart.data.order.data.deliveryPickup === "pickup"
          ? _c(
              "div",
              [
                _c("pickup-fields", {
                  attrs: {
                    cart: _vm.cart,
                    "delivery-date-data": _vm.deliveryDateData,
                    "delivery-time-options": _vm.deliveryTimeOptions,
                  },
                  on: { "update:pickup-date": _vm.pickupDateUpdated },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }