<template>
    <div>
        <form @submit.prevent="add" v-if="!outOfStock">
            <label for="quantity">Quantity</label>
            <div class="input-group">
                <input type="number"
                       class="form-control"
                       name="quantity"
                       @change="getEstimate"
                       v-model="quantity"
                       :min="0"
                       :max="limit"
                       :step="1"
                />
                <button type="submit" class="btn btn-primary">{{ displayText }}</button>
            </div>
            <div v-if="showEstimate" class="estimate text-primary fs-5 mt-2">{{ estimate }}</div>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";

export default {
    name: 'AddToCart',
    mounted() {
        if (this.maxQuantity > 0) {
            this.limit = this.maxQuantity
        }
    },
    computed: {
        showEstimate() {
            return Boolean(this.estimate);
        }
    },
    data() {
        return {
            quantity: 0,
            estimate: '',
            limit: 99999
        }
    },
    props: {
        productId: {
            type: Number,
            required: true,
        },
        addUrl: {
            type: String,
            required: true
        },
        displayText: {
            type: String,
            required: false,
            default: "Add To Cart"
        },
        token: {
            type: String,
            required: true
        },
        estimateToken: {
            type: String,
            required: true
        },
        estimateUrl: {
            type: String,
            required: true
        },
        maxQuantity: {
            type: Number,
            required: true,
        },
        outOfStock: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    methods: {
        add() {
            const self = this;
            const formData = new FormData();

            formData.append('ccm_token', this.token);
            formData.append('productID', this.productId);
            formData.append('quantity', this.quantity);

            axios.post(this.addUrl, formData)
                .then(function (response) {
                    const notification = Swal.fire({
                        animation: false,
                        showConfirmButton: false,
                        showCancelButton: false,
                        icon: "success",
                        title: 'Success',
                        html: `<p>${response.data.data.quantity} ${response.data.data.productName} were added to your cart</p>`,
                        footer: `
                        <div class="text-center">
                            <a href="/cart" class="mx-1 btn btn-primary">View Cart</a>
                            <button id="continue-shopping-button" type="button" class="mx-1 btn btn-outline-secondary">Continue Shopping</button>
                        </div>
                        `
                    });
                    document.getElementById('continue-shopping-button').addEventListener('click', function () {
                        Swal.close();
                    });
                    self.quantity = 0;
                    const event = new Event("store.cart.item-added");
                    window.dispatchEvent(event);
                }).catch(function (error) {
                Swal.fire({
                    animation: false,
                    icon: "error",
                    title: "Error",
                    text: error.response.data,
                    confirmButtonColor: '#ff0000'
                })
            })
            ;
        },

        getEstimate() {
            if (this.quantity > this.limit) {
                this.quantity = this.limit;
            }
            const formData = new FormData();
            const self = this;
            formData.append('ccm_token', this.estimateToken);
            formData.append('productID', this.productId);
            formData.append('quantity', this.quantity);

            axios.post(this.estimateUrl, formData)
                .then(function (response) {
                    self.estimate = response.data.data.subTotalCents > 0 ? response.data.data.subTotalFormatted : '';
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
    }
}
</script>