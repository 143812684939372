import { render, staticRenderFns } from "./DeliveryFields.vue?vue&type=template&id=698810eb"
import script from "./DeliveryFields.vue?vue&type=script&lang=js"
export * from "./DeliveryFields.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/bossod/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('698810eb')) {
      api.createRecord('698810eb', component.options)
    } else {
      api.reload('698810eb', component.options)
    }
    module.hot.accept("./DeliveryFields.vue?vue&type=template&id=698810eb", function () {
      api.rerender('698810eb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/application/themes/bossod/js/build/sales/components/DeliveryFields.vue"
export default component.exports