import moment from "moment";

export default class Order {
    constructor(data) {
        this.$dirty = false;
        this.id = null;
        this.billingCity = "";
        this.billingCityID = null;
        this.businessName = "";
        this.city = "";
        this.comments = "";
        this.contactLastName = "";
        this.contactName = "";
        this.deliveryCity = "";
        this.deliveryCityID = null;
        this.deliveryContactName = "";
        this.deliveryContractPhone = "";
        this.deliveryDateTime = null;
        this.deliveryTimeOption = null;
        this.deliveryTimeOptionDisplay = null;
        this.deliverDifferent = false;
        this.deliveryPickup = "";
        this.deliveryPostalCode = "";
        this.deliveryStreet = "";
        this.email = "";
        this.jobSiteContact = "";
        this.jobSitePhone = "";
        this.lineItems = {data: []};
        this.numPallet = 0;
        this.orderType = "";
        this.palletCost = "";
        this.palletCostCent = 0;
        this.phoneNumber = "";
        this.phoneNumberMobile = "";
        this.postalCode = "";
        this.province = "";
        this.pst = "";
        this.pstCent = 0;
        this.shippingPST = "";
        this.shippingPSTCent = 0;
        this.shippingTax = "";
        this.shippingTaxCent = 0;
        this.shippingTotal = "";
        this.shippingTotalCent = 0;
        this.status = "";
        this.statusName = "";
        this.streetAddress = "";
        this.subTotal = "";
        this.subTotalCent = 0;
        this.tax = "";
        this.taxCent = 0;
        this.total = "";
        this.totalCent = 0;
    }

    /**
     * @param data Object
     */
    setData(data) {
        if (typeof data !== 'object') {
            return;
        }
        const keys = Object.keys(data);
        keys.forEach(key => {
            this[key] = data[key];
        });
    }

    getFormData() {
        const formData = new FormData();
        const skipKeys = [
            '$dirty',
            'saveUrl',
            'deliveryTimeOptionDisplay',
            'lineItems',
            'numPallet',
            'orderType',
            'palletCost',
            'palletCostCent',
            'pst',
            'pstCent',
            'shippingPST',
            'shippingPSTCent',
            'shippingTax',
            'shippingTaxCent',
            'shippingTotal',
            'shippingTotalCent',
            'status',
            'statusName',
            'subTotal',
            'subTotalCent',
            'tax',
            'taxCent',
            'total',
            'totalCent'
        ];

        const dateFields = [
            'deliveryDateTime'
        ];

        for (const key in this) {
            // let's not push all data to the form as some is not relevant when saving a record
            if (skipKeys.indexOf(key) !== -1) {
                continue;
            }
            if (dateFields.indexOf(key) !== -1) {
                if (this[key] instanceof Date) {
                    formData.append(key, moment(this[key]).format("YYYY-MM-DD"));
                } else {
                    formData.append(key, this[key])
                }
            } else if (this.hasOwnProperty(key)) {
                formData.append(key, this[key]);
            }
        }

        return formData;
    }
}