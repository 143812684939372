<template>
    <div>
        <a class="position-relative btn btn-sm btn-secondary ms-4" href="/cart">
            <span
                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-success"
                v-if="currentNumItems > 0"
            >
                {{ currentNumItems }}
            </span>
            <i class="bi bi-cart4"></i> Cart
        </a>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'CartIcon',
    mounted() {
        window.addEventListener('store.cart.item-added', this.refreshCartQuantity);
        window.addEventListener('store.cart.refresh', this.refreshCartQuantity);
    },
    computed: {},
    data() {
        return {
            currentNumItems: this.numItems // initializing the data property with the prop value
        }
    },
    props: {
        numItems: {
            type: Number,
            default: 0
        }
    },
    methods: {
        refreshCartQuantity() {
            axios.get('/api/v1/sales/cart/quantity')
                .then(response => {
                    this.currentNumItems = response.data.quantity;
                })
                .catch(error => {
                    console.error("Error fetching cart quantity: ", error);
                });
        }
    }
}
</script>