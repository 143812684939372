<template>
    <div class="my-3">
        <h4>Pickup Details</h4>

        <div class="row">
            <div class="col-4 mb-3">
            <label for="pickupDatePicker" class="form-label">Pickup Date</label>
            <input type="text" name="pickupDatePicker" id="pickupDatePicker" class="form-control" readonly />
            </div>
            <div class="col-4 mb-3">
                <label for="deliveryTime" class="form-label">Pickup Time:</label>
                <select name="deliveryTime" class="form-control" v-model="cart.data.order.data.deliveryTimeOption" @change="timeUpdated">
                    <option value=""></option>
                    <option v-for="(value, key) in deliveryTimeOptions" :value="key">{{ value }}</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script>
import Cart from "../../../../../../../packages/sales/src/Sales/Javascript/models/cart";
import AirDatepicker from "air-datepicker";
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en';
import {moment} from "../../../../../../../concrete/bedrock/assets/fullcalendar/dist/fullcalendar";

export default {
    name: 'PickupFields',
    mounted() {
        const self = this;
        this.datePicker = new AirDatepicker('#pickupDatePicker', {
            dateFormat: 'yyyy-MM-dd',
            locale: localeEn,
            minDate: this.firstAvailablePickupDate,
            maxDate: this.lastAvailablePickupDate,
            onRenderCell({date, cellType}) {
                if (cellType === 'day') {
                    if (self.excludeDate(date)) {
                        return {
                            disabled: true,
                            classes: 'disabled-class'
                        }
                    }
                }
            },
            onSelect({date, formattedDate, datepicker}) {
                self.$emit('update:pickup-date', formattedDate);
            }
        });
    },
    computed: {
        pickupDateTime: function() {
            return this.cart.data.order.data.pickupDateTime;
        },
        firstAvailablePickupDate: function() {
            return this.deliveryDateData.meta.firstAvailablePickupDate ? this.deliveryDateData.meta.firstAvailablePickupDate : "";
        },
        lastAvailablePickupDate: function() {
            return this.deliveryDateData.meta.lastAvailablePickupDate ? this.deliveryDateData.meta.lastAvailablePickupDate : "";
        },
        disabledDates: function() {
            const deliveryDates = this.deliveryDateData.data.filter(item => item.type === 'both' || item.type==='pickup');
            return deliveryDates.map(item => item.dateYMD);
        }
    },
    data() {
        return {
            datePicker: null
        }
    },
    props: {
        cart: {
            type: Object,
            required: true,
            default: new Cart()
        },
        deliveryDateData: {
            type: Object,
            required: true
        },
        deliveryTimeOptions: {
            type: Object,
            required: false,
            default: () => {}
        }
    },
    methods: {
        excludeDate: function (date) {
            this.deliveryDateData.data.forEach(function(closedDate) {
                if (moment(date).isSame(closedDate.date, 'day')) {
                    return true;
                }
            });

            return false;
        },
        timeUpdated() {
            this.$emit('update:delivery-time-option', this.cart.data.order.data.deliveryTimeOption);
        },
        updateExcludedDates() {
            const self = this
            this.deliveryDateData.data.forEach(function (closedDate) {
                self.datePicker.disableDate(closedDate.date);
            })
        },
    },
    watch: {
        'deliveryDateData.data': {
            handler: function () {
                this.updateExcludedDates();
            },
            deep: true
        },
        firstAvailablePickupDate: function() {
            this.datePicker.update({
                minDate: this.firstAvailablePickupDate,
            });
            this.datePicker.disableDate(this.disabledDates);
        }
    }
}
</script>