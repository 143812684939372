import '@concretecms/bedrock/assets/bedrock/js/frontend';
import '@concretecms/bedrock/assets/navigation/js/frontend';
import '../../../../../../vendor/triple-i/js-plugins/data-url-handler';
import AOS from 'aos';
import "./sales/products";

import './nav_scroll';
import './top_navigation_bar'

document.addEventListener('DOMContentLoaded', function() {
    AOS.init();
}, false);

