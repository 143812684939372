var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "my-3" }, [
    _vm.showButton
      ? _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.updateOrder } },
          [_vm._v("Continue\n    ")]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }