<template>
    <div class="row gx-1 mb-1">
        <div class="col-4">{{lineItem.productName}}</div>
        <div class="col-2 text-end">{{lineItem.quantity}} {{lineItem.units}}</div>
        <div class="col-2 text-end">{{lineItem.productPrice}}</div>
        <div class="col-3 text-end">{{lineItem.subTotal}}</div>
        <div class="col-1 text-end"><button @click="confirmRemoveItem" class="btn btn-sm btn-outline-danger"><i class="bi bi-x"></i></button></div>
    </div>
</template>

<script>
import axios from 'axios';
import Swal from "sweetalert2";

export default {
    name: 'CartLineItem',
    mounted() {
        this.lineItem = this.item;
    },
    data() {
        return {
            lineItem: {}
        }
    },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    methods: {
        confirmRemoveItem: function() {
            const self = this;
            Swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want to remove this item from your cart?",
                confirmButtonText: 'Yes, remove it',
                showCancelButton: true,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary mx-1',
                    cancelButton: 'btn btn-outline-dark-grey mx-1',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    self.removeItem();
                }
            })
        },
        removeItem: function() {
            const self = this;
            const formData = new FormData();
            formData.append('ccm_token', this.lineItem.deleteToken);
            axios
                .post(self.lineItem.deleteURL, formData)
                .then(function (response) {
                    self.$emit('item-removed', response.data.data);
                    const event = new Event("store.cart.refresh");
                    window.dispatchEvent(event);
                }).catch((e) => {
                    console.info('caught error', e);
                    const event = new Event("store.cart.refresh");
                    window.dispatchEvent(event);
                });
        }
    }
}
</script>