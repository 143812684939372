<template>
    <div class="my-3" v-if="hasItems">
        <h4>Contact Details</h4>
        <div class="row">
            <div class="col-6 mb-3">
                <label for="businessName" class="form-label">Business Name (Optional):</label>
                <input type="text" name="businessName" v-model="cart.data.order.data.businessName"
                       class="form-control"/>
            </div>
        </div>

        <div class="row">
            <div class="col-6 mb-3">
                <label for="contactName" class="form-label">Name:</label>
                <input type="text" name="contactName" id="contactName" v-model="cart.data.order.data.contactName"
                       class="form-control"/>
            </div>
            <div class="col-6 mb-3">
                <label for="contactLastName" class="form-label">Last Name:</label>
                <input type="text" name="contactLastName" id="contactLastName"
                       v-model="cart.data.order.data.contactLastName" class="form-control"/>
            </div>
        </div>

        <div class="row">
            <div class="col-6 mb-3">
                <label for="streetAddress" class="form-label">Street Address:</label>
                <input type="text" name="streetAddress" id="streetAddress" v-model="cart.data.order.data.streetAddress"
                       class="form-control"/>
            </div>
            <div class="col-6 mb-3">
                <label for="billingCityID" class="form-label">City:</label>
                <select name="billingCityID" v-model="cart.data.order.data.billingCityID" class="form-control"
                        @change="updatedBillingCityID">
                    <option value=""></option>
                    <option v-for="city in deliveryCities" :value="city.id">{{ city.name }}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="col-6 mb-3">
                <label for="postalCode" class="form-label">Postal Code:</label>
                <input type="text" name="postalCode" id="postalCode" class="form-control"
                       v-model="cart.data.order.data.deliveryPostalCode"/>
            </div>
        </div>

        <div class="row">
            <div class="col-6 mb-3">
                <label for="phoneNumber" class="form-label">Phone Number:</label>
                <input type="tel" name="phoneNumber" id="phoneNumber" v-model="cart.data.order.data.phoneNumber"
                       class="form-control"/>
            </div>
            <div class="col-6 mb-3">
                <label for="email" class="form-label">Email:</label>
                <input type="email" name="email" id="email" v-model="cart.data.order.data.email" class="form-control"/>
            </div>
        </div>

        <div class="form-check">
            <input class="form-check-input" type="checkbox" id="checkCheck" v-model="deliverySame">
            <label class="form-check-label" for="checkCheck">Billing and shipping information is the same; including the
                phone number where we can reach you on the day of delivery:</label>
        </div>

        <div class="my-3" v-if="cart.data.order.data.deliverDifferent">
            <h3>Delivery Information</h3>
            <div class="row">
                <div class="col-6 mb-3">
                    <label for="deliveryContractName" class="form-label">Shipping Contact Name:</label>
                    <input type="text" name="deliveryContactName" id="deliveryContactName"
                           v-model="cart.data.order.data.deliveryContactName" class="form-control"/>
                </div>
                <div class="col-6 mb-3">
                    <label for="deliveryContactPhone" class="form-label">Shipping Contact Phone</label>
                    <input type="tel" name="deliveryContactPhone" id="deliveryContactPhone"
                           v-model="cart.data.order.data.deliveryContactPhone" class="form-control"/>
                </div>
            </div>

            <div class="row">
                <div class="col-6 mb-3">
                    <label for="deliveryStreet" class="form-label">Street Address:</label>
                    <input type="text" name="deliveryStreet" id="deliveryStreet" class="form-control"
                           v-model="cart.data.order.data.deliveryStreet"/>
                </div>
                <div class="col-6 mb-3">
                    <label for="deliveryCityID" class="form-label">City:</label>
                    <select name="deliveryCityID" v-model="cart.data.order.data.deliveryCityID" class="form-control"
                            @change="updateDeliveryCityID">
                        <option value=""></option>
                        <option v-for="city in deliveryCities" :value="city.id">{{ city.name }}</option>
                    </select>
                </div>
            </div>
        </div>

        <div class="mb-3">
            <label for="comments" class="form-label">Comments</label>
            <textarea name="comments" id="comments" class="form-control"
                      v-model="cart.data.order.data.comments"></textarea>
        </div>
    </div>
</template>

<script>
import Cart from "../../../../../../../packages/sales/src/Sales/Javascript/models/cart";
import AirDatepicker from "air-datepicker";
import 'air-datepicker/air-datepicker.css';
import localeEn from 'air-datepicker/locale/en';
import {moment} from "../../../../../../../concrete/bedrock/assets/fullcalendar/dist/fullcalendar";

export default {
    name: 'ContactDetails',
    mounted() {
        const self = this;

    },
    computed: {
        deliverySame: {
            get() {
                return !this.cart.data.order.data.deliverDifferent;
            },
            set(value) {
                this.cart.data.order.data.deliverDifferent = !value;
            }
        },
        hasItems() {
            return this.cart.data.order.data.lineItems.data.length > 0;
        }
    },
    data() {
        return {}
    },
    props: {
        cart: {
            type: Object,
            required: true,
            default: new Cart()
        },
        deliveryCities: {
            type: Array,
            required: true,
        },
    },
    methods: {
        updatedBillingCityID() {
            this.$emit('update:billing-city-id', this.cart.data.order.data.billingCityID);
        },
        updateDeliveryCityID() {
            this.$emit('update:delivery-city-id', this.cart.data.order.data.deliveryCityID);
        }
    }
}
</script>