import Order from "./order";

export default class Cart {
    constructor(data) {
        this.$dirty = false;
        this.loaded = false;
        this.data = {
            order: {
                data: new Order()
            }
        };
    }

    /**
     * @param data Object
     */
    setData(data) {
        if (typeof data !== 'object') {
            return;
        }
        const keys = Object.keys(data);
        keys.forEach(key => {
            this[key] = data[key];
        });
    }
}